<template>
   <div>
      <el-input placeholder="请输入患者姓名"
                suffix-icon="el-icon-search"
                v-model="findKey">
      </el-input>
      <el-button @click="search"
                 type="primary">查询</el-button>
      <el-table :data="searchData"
                style="width: 78%"
                :default-sort="{prop: 'dateTime', order: 'descending'}">
         <el-table-column label="日期"
                          width="300"
                          prop="dateTime"
                          sortable>
            <template slot-scope="scope">
               <i class="el-icon-time"></i>
               <span style="margin-left: 10px">{{ scope.row.dateTime|formatDate }}</span>
            </template>
         </el-table-column>
         <el-table-column prop="cname"
                          label="患者姓名"
                          align="center"
                          width="150">
         </el-table-column>
         <el-table-column label="方案类型"
                          align="center"
                          width="150">
            {{PlanName}}
         </el-table-column>
         <el-table-column prop="rtName"
                          label="历史方案"
                          align="center"
                          width="150">
         </el-table-column>
         <el-table-column label="就诊卡号"
                          align="center"
                          width="220">
            <template slot-scope="scope">{{
            scope.row.cid | hideMiddle
          }}</template>
         </el-table-column>
         <el-table-column label="方案时间"
                          align="center"
                          width="150"
                          prop="data">
            <template slot-scope="scope">{{
            scope.row.data.split(",")[0]
          }}</template>
         </el-table-column>
         <el-table-column label="光照强度"
                          align="center"
                          width="150"
                          prop="data">
            <template slot-scope="scope">{{
            scope.row.data.split(",")[1]
          }}</template>
         </el-table-column>
      </el-table>

   </div>
</template>

<script>
import { formatDate } from "../common/date"; // 在组件中引用date.js
// import { Message } from "element-ui";
// import Page from "./Page.vue";
import { createNamespacedHelpers } from "vuex";
import axios from "../http/axios";

// import api from "../http/api";
const { mapState, mapActions } = createNamespacedHelpers(
   "patient"
);
export default {
   data () {
      return {
         name: '',
         sum: 0,
         times: [],
         strength: "",
         findKey: "",
         value1: "",
         value2: "",
         searchData: [],
         dialogVisible: false,

         dataonLineListSelections: [],
      };
   },
   // mounted () {
   //    this.rport
   // },
   // updated () { this.rport },
   // created () { this.getIndex() },
   filters: {
      hideMiddle (val) {
         return `${val.substring(0, 3)}****${val.substring(val.length - 3)}`;
      },
      formatDate (time) {
         let date = new Date(time);
         return formatDate(date, "yyyy年MM月dd日hh时mm分秒");
      },
   },
   computed: {
      ...mapState(["VRRport", "rport"]),
      reverseSum () {
         return this.VRRport.reverse();
      },
      PlanName () {
         if (this.searchData.rtId = "RT1") {
            return "光照治疗"
         } else {
            return "VR治疗"
         }
      }
   },
   methods: {

      // getIndex (index) {
      //    console.log(index);
      //    this.sum = index + 1
      //    //这里的item是点击获取当前值的每一项内容
      //    //这里的index是点击获取当前值的下标

      // },
      ...mapActions(["getReoprtALl", "delVRone"]),
      // 通过关键字查询患者
      async search () {
         const data = await axios({
            url: `/Report/find?from=${this.value1}&to=${this.value2}&findKey=${this.findKey}  `,
            method: "get",
         });
         if (data.data.msg == "请求处理正常") {
            this.searchData = data.data.data;
         }
         // this.getReoprtALl()

      },
      /**
      * 表格排序事件处理函数
      * @param {object} {column,prop,order} 列数据|排序字段|排序方式
      */
      onSortChange ({ prop, order }) {
         this.searchData.sort(this.compare(prop, order));
      },

      /**
       * 排序比较
       * @param {string} propertyName 排序的属性名
       * @param {string} sort ascending(升序)/descending(降序)
       * @return {function}
       */
      compare (propertyName, sort) {
         return function (obj1, obj2) {
            var value1 = obj1[propertyName];
            var value2 = obj2[propertyName];
            if (typeof value1 === "string" && typeof value2 === "string") {
               const res = value1.localeCompare(value2, "zh");
               return sort === "ascending" ? res : -res;
            } else {
               if (value1 <= value2) {
                  return sort === "ascending" ? -1 : 1;
               } else if (value1 > value2) {
                  return sort === "ascending" ? 1 : -1;
               }
            }
         };
      },

   },
};
</script>

<style lang="scss" scoped>
.el-input {
   width: 200px;
}
.text {
   font-size: 14px;
}

.item {
   padding: 18px 0;
}
#cardBox {
   display: flex;
}
.box-card {
   width: 480px;
   border-radius: 20px;
   margin: 10px;
}
</style>
